import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs, and, orderBy } from "firebase/firestore";
import db from "../firebase";
import ReactPlayer from "react-player";
import { ReactComponent as Logo} from '../images/dale-play-sena.svg';
import "../styles/estilos.scss";
import AdsenseExample from "../components/AdsenseE";
import { Helmet } from "react-helmet";


function MaterialLensegua() {

  const [palabra, setPalabra] = useState('');
  const [tema, setTema] = useState('abecedario');
  const [resultados, setResultados] = useState([]);
  const [loadingClass, setLoadingClass] = useState('none');

  const capitalizeFirstLowercaseRest = str => {
    return (
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
} ;

  const clickBuscarPorPalabra = async () => {
    if (palabra !== '') {
      setLoadingClass('');
      const q = query(collection(db, "contenido"), 
                        and(
                          where("nombre", '>=', palabra.toLocaleLowerCase()), 
                          where("nombre", '<=', palabra.toLocaleLowerCase() + '\uf8ff')
                        ),
                      );
      const querySnapshot = await getDocs(q);
      setTimeout(() => {
        setLoadingClass('none');
        const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));
        setResultados(newData.length === 0 ? null : newData);
      }, 3000);
    }
  }

  const clickBuscarPorTema = async () =>{
    setLoadingClass('');
    const q = query(collection(db, "contenido"), 
                      where("leccion", "==", tema),
                      orderBy("orden")
                    );
    const querySnapshot = await getDocs(q);
    setTimeout(() => {
      setLoadingClass('none');
      const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));
      setResultados(newData);
    }, 3000);
  }

  useEffect(() => {
    setResultados([]);
  }, [palabra, tema])

  return(
    <React.Fragment>
      <Helmet>
          <title>Material de lengua de señas de Guatemala | LENSEGUA</title>
          <meta name="description" content="Aprende lengua de señas buscando las palabras que quieras aprender de LENSEGUA gracias al contenido gratis de Kitsord." />
      </Helmet>
      <div class="main-content">
        <div className={`loading ${loadingClass}`}>
          <div className={"loading-container"}>
            <div className={"loading-content"}>
              <div className={"loading-text"}>CARGANDO</div>
              <div className={"loading-text"}>Resultados estarán abajo</div>
              <div class="dot-pulse"></div>
            </div>
          </div>
        </div>
        <div class="content custom-content">
          <div class="container-4 w-container"><img src="images/kitsord_logo_imago.svg" loading="lazy" alt="" class="hello-image" />
            <h1 class="heading bold center">Contenido de LENSEGUA</h1>
            <h2 class="blog-section-title center"><strong>Acceso gratuito al contenido de aprendizaje de LENSEGUA gracias al equipo de Kitsord</strong></h2>
            <div class="blog-paragraphs mod-mbottom">El acceso a recursos educativos es un factor crucial para promover la inclusión y la equidad en nuestra sociedad. En este sentido, el equipo de Kitsord ha dado un paso significativo al hacer que el valioso contenido de aprendizaje de <strong>LENSEGUA</strong>, anteriormente disponible exclusivamente en su aplicación, ahora esté accesible de manera gratuita en el sitio web oficial de LENSEGUA. Esta iniciativa representa un avance importante para aquellos que desean aprender la Lengua de Señas de Guatemala y contribuir a una sociedad más inclusiva.<br/><br/></div>
            <div class="search-signs-form w-form">
              <div class="div-block-2">
                <h3 class="blog-section-title center">¿Qué seña deseas aprender?</h3>
                <div class="div-block-3">
                  <div class="w-layout-grid grid-3">
                    <div id="w-node-f7645fc8-82cb-90aa-e92c-e2f95770ec92-0263dc3a" class="buscar-por-tema">
                      <div class="buscar-title">Buscar por tema</div>
                      <select id="Por-leccion" value={tema} onChange={e => setTema(e.target.value) } name="Por-leccion" data-name="Por leccion" class="select-field w-select">
                        <option value="abecedario">Abecedario</option>
                        <option value="diasdelasem">Días de la semana</option>
                        <option value="meses">Meses</option>
                        <option value="colores">Colores</option>
                        <option value="frases">Frases/Saludos</option>
                        <option value="emociones">Emociones/Sentimientos</option>
                        <option value="familia">Familia</option>
                        <option value="preguntas">Preguntas</option>
                        <option value="lugares">Lugares</option>
                        <option value="comida">Comida</option>
                        <option value="animales">Animales</option>
                        <option value="numeros">Números</option>
                        <option value="departamentos">Departamentos</option>
                        <option value="ropa">Ropa</option>
                        <option value="adjetivos">Adjetivos</option>
                        <option value="verbos">Verbos</option>
                        <option value="tiempo">Tiempo</option>
                        <option value="cursos">Cursos educativos</option>
                        <option value="bebidas">Bebidas</option>
                        <option value="comercios">Comercios</option>
                        <option value="frutas">Frutas</option>
                        <option value="verduras">Verduras</option>
                        <option value="naturaleza">Naturaleza</option>
                        <option value="deportes">Deportes</option>
                      </select>
                      <button onClick={clickBuscarPorTema} id="buscar-por-tema" href="#" class="send-button w-node-_1789c2c3-0612-8056-a72a-2b06f1f3bc61-0263dc3a w-button">BUSCAR</button>
                    </div>
                    <div id="w-node-_8423ec6a-29ca-e724-ec02-ee1ca4a01ea9-0263dc3a" class="text-block-4">Ó</div>
                    <div id="w-node-cb08475f-4158-0705-184a-68f40e83a744-0263dc3a" class="buscar-por-palabra">
                      <div class="buscar-title">Buscar por palabra</div><input value={palabra} onChange={e => setPalabra(e.target.value)} class="sign-textfield normal-field remove w-input" maxlength="256" name="message-2" data-name="Message 2" placeholder="Ejemplo: mucho gusto" type="text" id="message-2" />
                      <button onClick={clickBuscarPorPalabra} id="buscar-por-palabra" href="#" class="send-button w-node-ced7a94c-9997-6558-4b4c-3c3b254ae164-0263dc3a w-button">BUSCAR</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <AdsenseExample /> */}
            <div class="resultados-senas">
              {/* Componente con resultados */}
              { (resultados != null && resultados.length !== 0) && 
                  <div class="resultados-container">
                    <div class="text-block-6">Resultados</div>
                    <div class="w-layout-grid resultados-grid">
                      {resultados.map((item, key) => 
                        <div key={item.id} id="w-node-bcb28e56-55ec-0082-db64-17ba95ad20be-0263dc3a" class="resultado">
                          <div class="resultado-head-container">
                            { item.tipo === 'jpg' && 
                              <div style={{position: 'relative', height: '100%'}}>
                                <img src={item.ubicacion} loading="lazy" alt="" class="resultado-imagen" /> 
                              </div>
                            }
                            { item.tipo === 'webm' && 
                              <div style={{position: 'relative', height: '100%'}}>
                                <ReactPlayer
                                    playing
                                    width='100%'
                                    height='100%'
                                    light= {true}
                                    loop = {true}
                                    playIcon = {<Logo />}
                                    url={[
                                      {src: item.ubicacion, type: 'video/webm'},
                                    ]}
                                  />
                              </div>
                            }
                          </div>
                          <div class="resultado-texto-container">
                            <div class="resultado-texto">{capitalizeFirstLowercaseRest(item.nombre)}</div>
                          </div>
                        </div>
                      )}
                    </div>
                </div>
              }
              {/* Componente sin resultados */}
              { (resultados == null) && 
                <div class="sin-resultados">
                  <div class="text-block-5">No hay resultados para tu búsqueda de {palabra}. <br/>Puedes buscar por tema para encontrar la palabra que estés queriendo aprender.</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <a href="https://kitsord.com/" target="_blank" class="w-inline-block w-clearfix">
          <div class="powered_txt">Powered by</div><img src="images/kitsord_logo_imago.svg" alt="" class="image-5" />
        </a>
      </div>
    </React.Fragment>
  );
}

export default MaterialLensegua;